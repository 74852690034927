$input-width: 320px;
$input-background-color: #FCFCFC;
$input-color: #333;
$input-border-radius: 4px;
$input-padding: 10px 14px;
$input-font-size: 16px;
$input-line-height: 22px;
$input-height: 44px;
$input-placeholder-color: #667085;
$input-margin-bottom: 6px;
$input-border: 1px solid #D0D5DD;
$input-font-weight: 400;

.time-picker-component {
    position: relative;
    //width: $input-width;

    &.fullWidth {
        width: 100%;

        .rc-time-picker {
            width: 100%;
        }
    }

    .rc-time-picker {
        display: block;

        .rc-time-picker-input {
            height: $input-height;
            width: 100%;
            background: $input-background-color;
            color: $input-color;
            border-radius: $input-border-radius;
            outline: none;
            padding: $input-padding;
            border: $input-border;
            font-size: $input-font-size;
            line-height: $input-line-height;
            font-weight: $input-font-weight;
            box-shadow: 0 3px 4px -5px rgba(24, 24, 28, 0.03), 0px 1px 2px rgba(24, 24, 28, 0.04);
        }

        .rc-time-picker-panel {
            position: absolute;
            background-color: #fff;
            z-index: 1050;
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
            max-height: 240px;
            overflow-y: auto; /* Add this line to make it scrollable */
            transform: translateZ(0); /* Add this line to fix scrolling on iOS devices */
        }

        .rc-time-picker-clear {
            top: 7px;
            font-weight: $input-font-weight;
            color: #939393;
        }
    }

    .time-picker-container {
        position: relative;
    }

    .time-picker-icon {
        position: absolute;
        top: 50%;
        right: 12px;
        transform: translateY(-50%);
        cursor: pointer;
        color: #939393;
    }

}

.rc-time-picker-panel {
    z-index: 9999 !important;

    .rc-time-picker-panel-inner {
        display: flex !important;
        flex-direction: column;


        .time-picker-close {
            padding: 5px;
            text-align: center;
            background: #ddd;
            cursor: pointer;
        }
    }

}
