.react-datepicker__time-container--with-today-button {
    right: -85px;
}

.react-datepicker {
    z-index: 999;
    position: relative;
}

.react-datepicker__day--outside-month{
    opacity: 0.4;
}

.react-datepicker__day--in-range, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
    background-color: #ffa202 !important;
    color: #ffffff !important;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
    background-color: inherit;
    color: inherit;
}
