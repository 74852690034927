.switch {
  position: relative;
  display: inline-block;
  height: 32px;
  min-width: 116px;

  &.disabled {
    .slider {
      cursor: not-allowed !important;
      opacity: 0.6 !important;
    }
  }

}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ECECEC;
  color: #5D7285;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: url("../images/tick.svg");
  height: 24px;
  width: 24px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  display: flex;
  justify-content: center;
  padding: 2px;
}

input.checked + .slider {
  background-color: #DFEDFE;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input.checked + .slider:before {
  -webkit-transform: translateX(82px);
  -ms-transform: translateX(82px);
  transform: translateX(82px);
}

/*------ ADDED CSS ---------*/
.on {
  display: none;
  left: 20px;
}

.off {
  right: 20px;
}

.on, .off {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  font-size: 14px;
  align-items: flex-end;
  font-family: Nunito, sans-serif;
  user-select: none;
}

input.checked + .slider .on {
  display: block;
  right: 0;
  color: #0C80E3;
}

input.checked + .slider .off {
  display: none;
}

/*--------- END --------*/

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
