.advanced-date-range-picker-component {
    display: flex;
    border-radius: 2px;
    background: #FFF;
    position: relative;

    .filter-option-item {
      padding: 10px 12px;
      border-right: 1px solid #FFA200;
      cursor: pointer;
      font-size: 13px;
      letter-spacing: 0.03px;

      &:last-child {
        border-right: none;
      }

      &.active {
        color: #fff;
        background: #FFA200;
        font-weight: 500;
      }
    }

  .custom-date-filter-wrapper {
    position: absolute;
    top: 38px;
    right: 0;

    .custom-date-filter-actions {
      text-align: center;
      margin-bottom: 10px;
    }
  }
}
