.physician-dashboard-screen {
  padding: 33px 24px;

  .physician-dashboard-screen-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 28px;

    .physician-dashboard-screen-actions {

    }

  }

  .physician-dashboard-screen-title {
    color: #1D1B20;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 15px;
  }

  .physician-dashboard-screen-content {
    .physician-dashboard-screen-cards {
      margin-top: 20px;
    }
  }
}

.physician-dashboard-statistics-card {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  background: #FFF;
  position: relative;

  .physician-dashboard-statistics-card-label-and-count {

    .physician-dashboard-statistics-card-count {
      font-size: 24px;
      letter-spacing: 0.036px;
      margin-bottom: 16px;
    }

    .physician-dashboard-statistics-card-label {
      font-size: 14px;
      letter-spacing: 0.021px;
    }

  }

  .physician-dashboard-statistics-card-percentage {
    color: #1C1C1C;
    font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: start;
    column-gap: 4px;
  }
}

.physician-dashboard-list-card {
  padding: 16px;
  background: #FFF;
  border-radius: 5px;

  .physician-dashboard-list-card-header {
    margin-bottom: 16px;

    .physician-dashboard-list-card-header-title {
      font-size: 16px;
      letter-spacing: 0.024px;
      margin-bottom: 8px;
    }

    .physician-dashboard-list-card-header-subtitle {
      color: #5D7285;
      font-size: 14px;
      letter-spacing: 0.048px;
    }
  }

  .physician-dashboard-list-card-content {
    padding: 0;
  }
}

.physician-dashboard-date-filter {
  &.filter-option-list {
    display: flex;
    border-radius: 2px;
    border: 0.5px solid #FFA200;
    background: #FFF;
    position: relative;

    .filter-option-item {
      padding: 5px 7px;
      border-right: 1px solid #FFA200;
      cursor: pointer;
      color: rgba(93, 114, 133, 0.70);
      font-size: 12px;
      letter-spacing: 0.03px;

      &:last-child {
        border-right: none;
      }

      &.active {
        color: #fff;
        background: #FFA200;
        font-weight: 500;
      }
    }
  }

  .custom-date-filter-wrapper {
    position: absolute;
    top: 30px;
    right: -20px;

    .custom-date-filter-actions {
      text-align: center;
      margin-bottom: 10px;
    }
  }
}

.recharts-default-legend {
  margin-left: 55px !important;
}

.recharts-legend-item-text{
  color: black !important;
}

.recharts-legend-item {
  margin-right: 16px !important;
  margin-top: 10px !important;
}

@keyframes shine {
  0% {
    background-position: -40px 0;
  }
  100% {
    background-position: 100% 0;
  }
}
