.avatar {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &.avatar-active {
      outline: 3px solid #d4e280;
    }
    .corner-name {
      position: absolute;
      left: 0;
      bottom: 0;
      display: flex;
      padding: 3px 5px;
      align-items: center;
      max-width: 100%;
      height: 25px;
      font-size: 14px;
      color: #fff;
      background-color: rgba(0,0,0,0.6);
      .anticon-audio-muted{
        margin-right: 5px;
      }
    }
    .center-name {
      font-size: 38px;
      font-weight: 700;
      color: #fff;
    }
  }